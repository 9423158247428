import { createSlice } from "@reduxjs/toolkit";

import {
  Poker,
  Deck,
  MonteCarlo_TexasHoldem,
  MonteCarlo_FiveCardDraw,
  MonteCarlo_Omaha,
  MonteCarlo_SevenCardStud,
  GameType,
} from "poker-hand-strength-calculator";

import { RankValuesMap, ERounds, MAX_MOBILE_VIEW_PORT } from "../../lib/consts";

import { getCoords } from "../../components/Felt";

const slice = createSlice({
  name: "pokerStore",
  initialState: {
    isActivelyCalculatingOdds: false,
    playerCount: 2,
    startRank: "2",
    endRank: "A",
    currentRound: 0,
    isMobileViewPort: true,
    players: [],
    board: [],
    deadCards: ["Xx", "Xx", "Xx", "Xx", "Xx", "Xx"],
    outCards: [],
    game: {
      name: GameType.TEXAS_HOLDEM,
      drawSequence: [{ player: 2 }, { board: 3 }, { board: 1 }, { board: 1 }],
    },
    // game: {
    //   name: GameType.OMAHA,
    //   drawSequence: [{ player: 4 }, { board: 3 }, { board: 1 }, { board: 1 }]
    // },
    // game: {
    //   name: GameType.FIVE_CARD_DRAW,
    //   drawSequence: [{ player: 2 }, { player: 1 }, { player: 1 }, { player: 1 }],
    // },
    // game: {
    //   name: GameType.SEVEN_CARD_STUD,
    //   drawSequence: [{ player: 3 }, { player: 1 }, { player: 1 }, { player: 1 }, { player: 1 }],
    // },
  },
  reducers: {
    calculateOdds,
    clearAllPlayersOdds,
    foldPlayer,
    resetBoardCard,
    resetDeadCard,
    resetPlayerCard,
    resetTable,
    omahaTestCase_1,
    omahaTestCase_2,
    omahaTestCase_3,
    setBoardCard,
    setCurrentRound,
    setDeadCard,
    setGame,
    setIsMobileViewPort,
    setPlayerCard,
    stepBack,
    stepForward,
    setAllPlayerCoords,
  },
});

function setIsMobileViewPort(state, action) {
  const { payload } = action;
  const { isMobileViewPort } = payload;
  return { ...state, isMobileViewPort };
}

function setCurrentRound(state, action) {
  const { payload } = action;
  const { currentRound } = payload;
  return { ...state, currentRound };
}

function resetTable(state, action) {
  const { payload } = action;
  const { startRank, endRank, playerCount, gameType } = payload;

  state.playerCount = playerCount;

  state.deck = new Deck(startRank, endRank);
  state.deck.shuffle();
  const players = [];
  for (let i = 1; i <= playerCount; i++) {
    players.push({ name: "Player " + i });
  }

  state.game.name = gameType ? gameType : state.game.name;
  switch (state.game.name) {
    case GameType.OMAHA:
      state.game.drawSequence = [{ player: 4 }, { board: 3 }, { board: 1 }, { board: 1 }];
      break;
    case GameType.TEXAS_HOLDEM:
      state.game.drawSequence = [{ player: 2 }, { board: 3 }, { board: 1 }, { board: 1 }];
      break;
    case GameType.FIVE_CARD_DRAW:
      state.game.drawSequence = [{ player: 1 }, { player: 1 }, { player: 1 }, { player: 1 }, { player: 1 }];
      break;
    case GameType.SEVEN_CARD_STUD:
      state.game.drawSequence = [{ player: 3 }, { player: 1 }, { player: 1 }, { player: 1 }, { player: 1 }];
      break;
    default:
      break;
  }

  players.forEach((player) => resetPlayer(state, player));
  state.players = players;

  state.board = [];
  state.currentRound = ERounds.GAME_START;
  setAllPlayerCoords(state);
  resetAllDeadCards(state);
}

function resetBoardCard(state, action) {
  const { payload } = action;
  const { card, cardIndex } = payload;
  const board = state.board;
  board[cardIndex] = { card: "Xx", winner: false };

  state.deck.returnCardToDeck(card);

  calculateHandStrength(state);
}

function setBoardCard(state, action) {
  const { payload } = action;
  const { card, cardIndex } = payload;
  const board = state.board;
  board[cardIndex] = { card, winner: false };

  state.deck.removeFromDeck(card);

  calculateHandStrength(state);
}

function resetPlayerCard(state, action) {
  const { payload } = action;
  const { playerIndex, cardIndex, card } = payload;
  const player = state.players[playerIndex];

  if (card !== "Xx") {
    state.deck.returnCardToDeck(card);
  }
  player.cards[cardIndex] = { card: "Xx", winner: false };

  // if any player cards are set...
  const haveSetCards = player.cards.some(({ card }) => card !== "Xx");
  resetPlayerHand(state, player);
  if (haveSetCards) {
    calculateHandStrength(state);
  }
}

function setPlayerCard(state, action) {
  const { payload } = action;
  const { card, playerIndex, cardIndex } = payload;
  state.players[playerIndex].cards[cardIndex] = { card, winner: false };
  state.deck.removeFromDeck(card);

  // update round if needed
  if (state.currentRound === ERounds.GAME_START) {
    state.currentRound++;
  }

  calculateHandStrength(state);
}

function resetDeadCard(state, action) {
  const { payload } = action;
  const { cardIndex, card } = payload;
  if (card !== "Xx") {
    state.deck.returnCardToDeck(card);
  }
  state.deadCards[cardIndex] = "Xx";
  // calculateHandStrength(state);
}

function resetAllDeadCards(state) {
  state.deadCards = new Array(6);
  state.deadCards.fill("Xx");
}

function setDeadCard(state, action) {
  const { payload } = action;
  const { card, cardIndex } = payload;
  state.deadCards[cardIndex] = card;
  state.deck.removeFromDeck(card);

  // calculateHandStrength(state);
}

function stepBack(state) {
  if (state.currentRound === ERounds.GAME_START) {
    return;
  }
  const drawSequence = state.game.drawSequence[state.currentRound - 1];
  if (drawSequence.player) {
    state.players.forEach((player) => {
      for (let i = 0; i < drawSequence.player; i++) {
        const { card } = player.cards.pop();
        state.deck.returnCardToDeck(card);
      }
      if (player.cards.length === 0) {
        resetPlayer(state, player);
      }
    });
  } else if (drawSequence.board) {
    for (let i = 0; i < drawSequence.board; i++) {
      const { card } = state.board.pop();
      state.deck.returnCardToDeck(card);
    }
  }

  if (state.currentRound > ERounds.GAME_START) {
    state.currentRound--;
  }
  if (state.currentRound > ERounds.GAME_START) {
    calculateHandStrength(state);
  }
}

function stepForward(state) {
  if (state.currentRound > state.game.drawSequence.length) {
    return { ...state };
  }

  const drawRoundsSoFar = state.game.drawSequence.slice(0, state.currentRound);
  let missingCards = false;

  // fill in missing cards
  for (let round of drawRoundsSoFar) {
    if (round.player) {
      state.players.forEach((player) => {});
      for (let i = 0; i < state.players.length; i++) {
        for (let j = 0; j < state.players[i].cards.length; j++) {
          const { card } = state.players[i].cards[j];
          if (card === "Xx") {
            missingCards = true;
            const newCard = state.deck.draw(1)[0];
            state.players[i].cards.splice(j, 1, { card: newCard, winner: false });
          }
        }
      }
    } else if (round.board) {
      for (let i = 0; i < state.board.length; i++) {
        const { card } = state.board[i];
        if (card === "Xx") {
          missingCards = true;
          const newCard = state.deck.draw(1)[0];
          state.board[i] = { card: newCard, winner: false };
        }
      }
    }
  }

  // deal next round
  if (!missingCards) {
    const drawSequence = state.game.drawSequence[state.currentRound];
    if (drawSequence?.player) {
      state.players.forEach((player) => {
        for (let i = drawSequence.player - 1; i >= 0; i--) {
          if (player.cards[i].card === "Xx") {
            player.cards.shift();
          }
          let card = state.deck.draw(1)[0];
          player.cards.push({ card, winner: false });
        }
      });
    } else if (drawSequence?.board) {
      for (let i = 0; i < drawSequence.board; i++) {
        let card = state.deck.draw(1)[0];
        state.board.push({ card, winner: false });
      }
    }
    if (state.currentRound < state.game.drawSequence.length) {
      state.currentRound++;
    }
    if (state.currentRound >= ERounds.GAME_START) {
      calculateHandStrength(state);
    }
  }
}

function setGame(state, action) {
  const { payload } = action;
  const { gameType } = payload;
  state.game.name = gameType;
}

function clearAllPlayersOdds(state) {
  state.players.forEach((player) => {
    player.tiePercentage = null;
    player.winPercentage = null;
  });
}

function omahaTestCase_1(state, action) {
  // state.game.name = GameType.OMAHA;

  // state.players = [];
  // state.players.push({ cards: [{ card: "Ac" }, { card: "Kc" }, { card: "Qc" }, { card: "Jc" }] });
  // state.players.push({ cards: [{ card: "Ad" }, { card: "Kd" }, { card: "Qd" }, { card: "Td" }] });

  // state.currentRound = 4;

  return {
    ...state,
    currentRound: 3,
    game: { ...state.game, name: GameType.OMAHA },
    players: [
      { cards: [{ card: "Ad" }, { card: "6d" }, { card: "5s" }, { card: "As" }] },
      { cards: [{ card: "Kh" }, { card: "Kc" }, { card: "Tc" }, { card: "Jh" }] },
    ],
    board: [{ card: "9c" }, { card: "7c" }, { card: "7h" }, { card: "Qh" }],
  };
}

function omahaTestCase_2(state, action) {
  return {
    ...state,
    currentRound: 4,
    game: { ...state.game, name: GameType.OMAHA },
    players: [
      { cards: [{ card: "Ac" }, { card: "Kc" }, { card: "Qc" }, { card: "Jc" }] },
      { cards: [{ card: "Ad" }, { card: "Kd" }, { card: "Qd" }, { card: "Td" }] },
    ],
    board: [{ card: "As" }, { card: "Ks" }, { card: "Js" }, { card: "9c" }, { card: "Th" }],
  };
}

function omahaTestCase_3(state, action) {
  return {
    ...state,
    currentRound: 4,
    game: { ...state.game, name: GameType.OMAHA },
    players: [
      { cards: [{ card: "Kd" }, { card: "8h" }, { card: "Th" }, { card: "As" }] },
      { cards: [{ card: "2s" }, { card: "Ac" }, { card: "4d" }, { card: "Ah" }] },
    ],
    board: [{ card: "5h" }, { card: "7s" }, { card: "Kc" }, { card: "3h" }, { card: "Qc" }],
  };
}

function foldPlayer(state, action) {
  const { payload } = action;
  const { playerIndex } = payload;

  // add folded cards to the deadcards
  state.players[playerIndex].cards.forEach(({ card }) => {
    addDeadCard(state, card);
    state.deck.removeFromDeck(card);
  });
  state.players.splice(playerIndex, 1);
}

function addDeadCard(state, card) {
  let cardPlaced = false;
  for (let i = 0; i < state.deadCards.length; i++) {
    if (state.deadCards[i] === "Xx") {
      state.deadCards[i] = card;
      cardPlaced = true;
      break;
    }
  }
  if (!cardPlaced) {
    state.deadCards.push(card);
  }
}

function calculateOdds(state) {
  if (state.currentRound === ERounds.GAME_START) {
    return { ...state };
  }

  let game = null;
  switch (state.game.name) {
    case GameType.TEXAS_HOLDEM:
      game = MonteCarlo_TexasHoldem;
      break;

    case GameType.OMAHA:
      game = MonteCarlo_Omaha;
      break;

    case GameType.FIVE_CARD_DRAW:
      game = MonteCarlo_FiveCardDraw;
      break;

    case GameType.SEVEN_CARD_STUD:
      game = MonteCarlo_SevenCardStud;
      break;

    default:
      return;
  }

  const board = state.board.map(({ card }) => card).filter((card) => card !== "Xx");
  const players = state.players.map((player) => player.cards.map(({ card }) => card).filter((card) => card !== "Xx"));
  const deadCards = state.deadCards;

  // if players have no cards, reset all players
  const noPlayersCards = players.flat().length === 0;
  if (noPlayersCards) {
    state.currentRound = ERounds.GAME_START;
    for (const player of state.players) {
      resetPlayerHand(state, player);
    }
    return;
  }

  const iterations = state.game.name === GameType.OMAHA ? 1_000 : 6_000;
  const calculator = new game(iterations);

  const result = calculator.simulate({ players, board, deadCards });

  for (let i = 0; i < result.length; i++) {
    const player = result[i];
    state.players[i].tiePercentage = player.tie;
    state.players[i].winPercentage = player.win;
  }

  setOutCards(state);

  highlightWinningCards(state);
}

// assuming 2 players
function getPlayerWithOutsIndex(state) {
  if (state.players[0].winPercentage > state.players[1].winPercentage) {
    return 1;
  }
  return 0;
}

function setOutCards(state) {
  const TURN = 3;
  if (state.currentRound !== TURN) {
    return;
  }

  const poker = new Poker();
  const result = {};

  // run the deck for outs
  for (let i = 0; i < state.deck.cards.length; i++) {
    const deckCard = state.deck.cards[i];

    const player0Cards = state.players[0].cards.map((o) => o.card);
    const player1Cards = state.players[1].cards.map((o) => o.card);
    const boardCards = state.board.map((o) => o.card);
    const player0Hand = [...player0Cards, ...boardCards, deckCard];
    const player1Hand = [...player1Cards, ...boardCards, deckCard];
    const player0Strength = poker.evaluateStrength(player0Hand);
    const player1Strength = poker.evaluateStrength(player1Hand);
    result[deckCard] = [player0Strength, player1Strength];
  }
  const playerIndex = getPlayerWithOutsIndex(state);
  const outCards = Object.entries(result)
    .filter(([key, value]) => {
      if (playerIndex === 0) {
        return value[0] > value[1];
      }
      return value[0] < value[1];
    })
    .map((o) => o[0]);
  // console.log("outCards", outCards);
  outCards.sort((a, b) => b[0] - a[0]);
  state.outCards = outCards;
}

function setAllPlayerCoords(state) {
  const element = document.querySelector(".felt");
  let width = element?.clientWidth || MAX_MOBILE_VIEW_PORT;
  let height = element?.clientHeight || MAX_MOBILE_VIEW_PORT;
  const playerCount = state.players.length;
  const coords = getCoords({ width, height, playerCount, gameType: state.game.name });
  coords.forEach((playerCoords, index) => {
    state.players[index].coords = playerCoords;
  });
}

function resetPlayer(state, player) {
  const initialPlayerCards = state.game.drawSequence[0].player;
  player.cards = [];
  for (let i = 0; i < initialPlayerCards; i++) {
    player.cards.push({ card: "Xx", winner: false });
  }
  resetPlayerHand(state, player);
}

function resetPlayerHand(state, player) {
  player.tiePercentage = null;
  player.winPercentage = null;
  player.handStrengthCode = null;
  player.handStrengthName = null;
}

function calculateHandStrength(state) {
  switch (state.game.name) {
    case GameType.FIVE_CARD_DRAW:
      calculateHandStrength_TexasHoldem(state);
      break;

    case GameType.TEXAS_HOLDEM:
      calculateHandStrength_TexasHoldem(state);
      break;
    case GameType.OMAHA:
      calculateHandStrength_Omaha(state);
      break;

    case GameType.SEVEN_CARD_STUD:
      calculateHandStrength_TexasHoldem(state);
      break;

    default:
      break;
  }
}

function calculateHandStrength_Omaha(state) {
  const poker = new Poker();
  const boardCards = state.board.map(({ card }) => card).filter((card) => card !== "Xx");
  const boardCombos = combinations(boardCards, 3);
  state.players.forEach((player, index) => {
    let strength = "0";
    const playerCards = player.cards.map(({ card }) => card).filter((card) => card !== "Xx");
    const playerCombos = combinations(playerCards, 2);

    // if no board
    // initlal preflop
    if (boardCombos.length === 0) {
      playerCombos.forEach((playerCombo) => {
        const hand = [...playerCombo];
        const strengthCode = poker.evaluateStrength(hand);
        if (strengthCode > strength) {
          strength = strengthCode;
        }
        if (strength !== "0") {
          player.handStrengthCode = strength;
          player.handStrengthName = poker.getHandStrengthName(strength);
        }
      });
    } else {
      playerCombos.forEach((playerCombo) => {
        boardCombos.forEach((boardCombo) => {
          const hand = [...playerCombo, ...boardCombo];
          const strengthCode = poker.evaluateStrength(hand);
          if (strengthCode > strength) {
            strength = strengthCode;
          }
          if (strength !== "0") {
            player.handStrengthCode = strength;
            player.handStrengthName = poker.getHandStrengthName(strength);
          }
        });
      });
    }
    return { ...state };
  });
}

function calculateHandStrength_TexasHoldem(state) {
  const boardCards = state.board.map(({ card }) => card).filter((card) => card !== "Xx");
  state.players.forEach((player) => {
    const poker = new Poker();
    const playerCards = player.cards.map(({ card }) => card).filter((card) => card !== "Xx");
    const hand = [...playerCards, ...boardCards];
    const strengthCode = poker.evaluateStrength(hand);
    player.handStrengthCode = strengthCode;
    player.handStrengthName = poker.getHandStrengthName(strengthCode);
  });
  return { ...state };
}

function combinations(arr, k) {
  let result = [];
  let combination = [];

  function combine(start, depth) {
    if (depth === k) {
      result.push([...combination]);
      return;
    }

    for (let i = start; i < arr.length; i++) {
      combination.push(arr[i]);
      combine(i + 1, depth + 1);
      combination.pop();
    }
  }

  combine(0, 0);
  return result;
}

function highlightWinningCards(state) {
  // clear all board and player winner cards
  // clear board

  for (let i = 0; i < state.board.length; i++) {
    state.board[i].winner = false;
  }
  // clear players
  for (let i = 0; i < state.players.length; i++) {
    const player = state.players[i];
    for (let j = 0; j < player.cards.length; j++) {
      player.cards[j].winner = false;
    }
  }

  // 7-card: is 5 rounds.
  if (state.game.name === GameType.SEVEN_CARD_STUD && state.currentRound < 5) {
    return;
  }

  if (
    state.currentRound < 4 ||
    state.board.map(({ card }) => card).some((card) => card === "Xx") ||
    (state.board.length > 0 && state.board.length < 5)
  ) {
    return;
  }

  state.players.forEach((player) => {
    if (player.winPercentage !== 1 && player.tiePercentage !== 1) {
      return;
    }
    const [strength, numericRanks, suitNumber] = player.handStrengthCode.split(".");
    const winningHandNumeric = numericRanks.split(",");
    const ranks = winningHandNumeric.map((card) => RankValuesMap[parseInt(card)]);

    const playerCardsRanks = player.cards.map(({ card }) => card.charAt(0));
    const boardCardsRanks = state.board.map(({ card }) => card.charAt(0));
    const playerCardsRanksNotOnBoard = playerCardsRanks.filter((rank) => !boardCardsRanks.includes(rank));

    // sort by player cards at the end.  We'll traverse in reverse order
    ranks.sort((a, b) => (playerCardsRanksNotOnBoard.includes(a) ? 1 : -1));

    // flush hands
    const FLUSH = 6;
    const STRAIGHT_FLUSH = 9;
    const ROYAL_FLUSH = 10;
    // const map1 = { c: 1, d: 2, h: 3, s: 4 };
    const map2 = { 1: "c", 2: "d", 3: "h", 4: "s" };
    const flushSuit = map2[parseInt(suitNumber)];
    const isFlush = (strength) => [FLUSH, STRAIGHT_FLUSH, ROYAL_FLUSH].includes(parseInt(strength));

    let playerCardsSelected = 0;
    // player
    for (let r = ranks.length - 1; r >= 0; r--) {
      const winnerRank = ranks[r];
      for (let j = 0; j < player.cards.length; j++) {
        if (state.game.name === GameType.OMAHA && playerCardsSelected === 2) {
          break;
        }
        if (player.cards[j].winner) {
          continue;
        }

        const currRank = player.cards[j].card.charAt(0);
        const currSuit = player.cards[j].card.charAt(1);
        if (winnerRank === currRank) {
          if (isFlush(strength) && currSuit !== flushSuit) {
            continue;
          }
          player.cards[j].winner = true;
          const index = ranks.indexOf(currRank);
          ranks.splice(index, 1);
          playerCardsSelected++;
          break;
        }
      }
    }

    // board
    for (let r = ranks.length - 1; r >= 0; r--) {
      const winnerRank = ranks[r];
      for (let j = 0; j < state.board.length; j++) {
        if (state.board[j].winner) {
          continue;
        }

        const currRank = state.board[j].card.charAt(0);
        const currSuit = state.board[j].card.charAt(1);
        if (winnerRank === currRank) {
          if (isFlush(strength) && currSuit !== flushSuit) {
            continue;
          }
          state.board[j].winner = true;
          const index = ranks.indexOf(currRank);
          ranks.splice(index, 1);
          playerCardsSelected++;
          break;
        }
      }
    }
  });

  return { ...state };
}

export const actions = slice.actions;
export const reducer = slice.reducer;
