import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { Poker, GameType } from "poker-hand-strength-calculator";

import { actions as modalStoreActions } from "../store/slices/modalStoreSlice";
import { actions as pokerStoreActions } from "../store/slices/pokerStoreSlice";

import Player from "./Player";
import Card from "./Card";
import SelectCardModal from "./SelectCardModal";
import DeadCards from "./DeadCards";
import Outs from "./Outs";

import { MAX_MOBILE_VIEW_PORT } from "../lib/consts";

function polarToCartesian(centerX, centerY, radiusX, radiusY, angleInDegrees) {
  var angleInRadians = (angleInDegrees * Math.PI) / 180.0;
  const x = centerX + radiusX * Math.cos(angleInRadians);
  const y = centerY + radiusY * Math.sin(angleInRadians);
  return {
    x: Math.floor(x),
    y: Math.floor(y),
  };
}

export const getCoords = ({ width, height, playerCount, isMobileViewPort, gameType }) => {
  // const rx = width * 0.6;
  // const ry = height * 0.6;
  const borderSize = 30;
  const rx = (width + borderSize) / 2;
  const ry = (height + borderSize) / 2;

  const result = [];
  const angles = [];
  switch (playerCount) {
    case 2:
      angles.push(180 + 90 * 1, 180 + 90 * 3);
      break;
    case 3:
      angles.push(180 + 60 * 1.5, 180 + 60 * 4, 180 + 60 * 5);
      break;
    case 4:
      angles.push(180 + 60 * 2, 180 + 60 * 4, 180 + 60 * 5, 180 + 60 * 7);
      break;
    case 5:
      if (isMobileViewPort) {
        angles.push(180 + 60 * 1.5, 180 + 60 * 2.5, 180 + 60 * 4, 180 + 60 * 5, 180 + 60 * 6.5);
      } else {
        angles.push(180 + 60 * 1.5, 180 + 60 * 2.25, 180 + 60 * 4, 180 + 60 * 5, 180 + 60 * 6.75);
      }
      break;
    case 6:
      if (isMobileViewPort) {
        angles.push(180 + 60 * 1.5, 180 + 60 * 2.25, 180 + 60 * 3.75, 180 + 60 * 4.5, 180 + 60 * 5.25, 180 + 60 * 6.75);
      } else {
        angles.push(180 + 60 * 1.5, 180 + 60 * 2.25, 180 + 60 * 3.75, 180 + 60 * 4.5, 180 + 60 * 5.25, 180 + 60 * 6.75);
      }
      break;
    case 7:
      if (isMobileViewPort) {
        angles.push(
          180 + 36 * 1,
          180 + 36 * 2.5,
          180 + 36 * 4,
          180 + 36 * 5.75,
          180 + 36 * 6.75,
          180 + 36 * 8.25,
          180 + 36 * 9.25
        );
      } else {
        angles.push(
          180 + 36 * 1,
          180 + 36 * 2.5,
          180 + 36 * 4,
          180 + 36 * 5.75,
          180 + 36 * 7,
          180 + 36 * 8,
          180 + 36 * 9.25
        );
      }
      break;
    case 8:
      if (isMobileViewPort) {
        angles.push(
          180 + 36 * 1.75,
          180 + 36 * 3.25,
          180 + 36 * 4.25,
          180 + 36 * 5.75,
          180 + 36 * 6.75,
          180 + 36 * 8.25,
          180 + 36 * 9.25,
          180 + 36 * 10.75
        );
      } else {
        angles.push(
          180 + 36 * 1.75,
          180 + 36 * 3.25,
          180 + 36 * 4.25,
          180 + 36 * 5.75,
          180 + 36 * 6.75,
          180 + 36 * 8.25,
          180 + 36 * 9.25,
          180 + 36 * 10.75
        );
      }
      break;
    case 9:
      if (isMobileViewPort) {
        angles.push(
          180 + 36 * 1.5,
          180 + 36 * 3.5,
          180 + 36 * 4.25,
          180 + 36 * 5.5,
          180 + 36 * 6.25,
          180 + 36 * 7.5,
          180 + 36 * 8.75,
          180 + 36 * 9.5,
          180 + 36 * 10.75
        );
      } else {
        angles.push(
          180 + 36 * 2,
          180 + 36 * 3,
          180 + 36 * 4.25,
          180 + 36 * 5.5,
          180 + 36 * 6.5,
          180 + 36 * 7.5,
          180 + 36 * 8.5,
          180 + 36 * 9.5,
          180 + 36 * 10.75
        );
      }
      break;

    default:
      break;
  }

  for (var i = 0; i < playerCount; i++) {
    const offset = [GameType.SEVEN_CARD_STUD].includes(gameType) ? -25 : 0;
    var point = polarToCartesian(width / 2, height / 2, rx + offset, ry + offset, angles[i]);
    result.push(point);
  }

  return result;
};

export default function Felt() {
  const dispatch = useDispatch();
  const { isMobileViewPort, playerCount, board, players, game, currentRound, outCards } = useSelector(
    (state) => state.pokerStore
  );
  const { isModalOpen } = useSelector((state) => state.modalStore);

  useEffect(() => {}, []);

  const onResize = () => {
    dispatch(pokerStoreActions.setIsMobileViewPort({ isMobileViewPort: window.innerWidth <= MAX_MOBILE_VIEW_PORT }));
    dispatch(pokerStoreActions.setAllPlayerCoords());
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [playerCount, isMobileViewPort]);

  const allClassNames = classNames("felt", game.name);

  return (
    <>
      <Outs outCards={outCards} />
      <div className={allClassNames}>
        <div className="board">
          {currentRound === 0 ? (
            <span>
              Deal randomly or <br /> choose cards. <br />
              Odds are calculated automatically.
            </span>
          ) : (
            board.map(({ card, winner }, cardIndex) => {
              return (
                <Card
                  key={cardIndex}
                  cardIndex={cardIndex}
                  card={card}
                  isBoardCard={true}
                  isWinner={winner}
                  onClick={() => {
                    dispatch(pokerStoreActions.resetBoardCard({ card, cardIndex }));
                    dispatch(modalStoreActions.openModal({ type: "board", cardIndex }));
                  }}
                />
              );
            })
          )}
        </div>
        {players.map((player, index) => {
          return (
            <>
              <Player
                key={index}
                playerIndex={index}
                player={player}
                coords={player?.coords}
                outCards={player?.outCards}
              />
            </>
          );
        })}
      </div>
      <SelectCardModal isOpen={isModalOpen} />
      <DeadCards />
    </>
  );
}
